import React from "react";

import { Container, Typography, Box } from "@material-ui/core";

import Layout from "../layout";
import Seo from "../seo";

import { Link } from "@runly/gatsby-theme";

const HelpPage = ({ location }) => (
	<Layout location={location} maxWidth="md">
		<Seo title="404 Page not found" />

		<Container maxWidth="md">
			<Box textAlign="center">
				<Typography variant="h1" gutterBottom>
					404
				</Typography>
				<Typography gutterBottom>
					We couldn't find anything at {location.pathname}
				</Typography>
				<Typography>
					<Link to="/help/">Do you need help?</Link>
				</Typography>
			</Box>
		</Container>
	</Layout>
);

export default HelpPage;
